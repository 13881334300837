$(document).ready(function(){
  $('.team .team__content').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    touchMove: true,
    focusOnSelect: true,
    arrows: false,
    vertical: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
  $('.welcome .welcome--box').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    touchMove: true,
    focusOnSelect: true,
    arrows: true,
    vertical: false,
    dots: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
  
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
  
        },
      },
    ],
  });


  //Modal Video
  $("main").append(
    '<div aria-hidden="true" aria-labelledby="myLargeModalLabel" class="modal fade modal-video" role="dialog" tabindex="-1">' +
      '<div class="modal-dialog modal-lg">' +
        '<div class="modal-content">' +
          '<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true" data-bs-dismiss="modal" >Close</span></button>'+
          '<div class="content-src">&nbsp;'+
            '<video src="" autoplay controls>'+
              'Tu navegador no admite el elemento <code>video</code>.'+
            '</video>'+
          '</div>' +
        "</div>" +
      "</div>" +
    "</div>"
  );
  $(".data-video").on("click", function (e) {
    $videoSrc = $(this).attr("data-bs-src");
    if($(".modal-video").find('video').attr("src") != $videoSrc ){
      $(".modal-video").find('video').attr("src", $videoSrc );
    }else{
      $(".modal-video").find('video').trigger('play');
    }
  });
  $(".modal-video").on("hidden.bs.modal", function () {
    $(".modal-video").find('video').trigger('pause');
  });
  $('.nav-link').on('click',function(){
    $(".navbar-toggler").trigger("click");
  })
});
